<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="heading" hidden>Вопросы-ответы</h1>
		<h2 class="heading heading__hr hr__bold">Вопросы экспертам дорожного хозяйства</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list back">
						<li><router-link :to="{name: 'FaqExperts'}" class="link__btn btn__blue">Назад</router-link></li>
					</ul>
				</nav>
				<form class="subscribe-form__ask">
					<fieldset>
						<legend>Задайте свой вопрос эксперту! Ответ будет опубликован на нашем сайте.</legend>
						<router-link :to="{ name: 'AskQuestionExpert' }" type="submit" class="link__btn btn__transparent-bg">Задать</router-link>
					</fieldset>
				</form>
			</section>
			<article class="kb--list faq--article">
				<h3>{{getQuestion.title}}</h3>
				<p v-html="getQuestion.question"></p>
				<hr class="hr__blue">
				<br>
				<h4>Ответ</h4>
				<p v-html="getQuestion.answer"></p>
			</article>
		</section>
	</main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	computed: mapGetters(['getQuestion']),
	methods: mapActions(['fetchQuestion']),
	mounted() {
		this.fetchQuestion(this.$route.params.question)
	}

}
</script>

<style>

</style>
